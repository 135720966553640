import React from "react";
import './LayerMenu.css'
import TickImg from '../media/tick_green.png';
import UntickedImg from '../media/empty.webp';
import BackArrowImg from '../media/ba_white.png';
import { Link } from "react-router-dom";

const LayerMenu = (options)=>{

    const {layers, menuItemClick} = options;

    const menuStyle = {right: "0px"}

    //when the expander button is clicked
    const handleExpanderClick = (evt)=>{
        const expanderEle = evt.target;
        const menuEle = expanderEle.parentElement;
        if(menuEle.style.right === "0px"){
            expanderEle.innerHTML =  "Menu";
            menuEle.style.right = "-200px"
        } else {
            menuEle.style.right = "0px";
            expanderEle.innerHTML = "Hide";
        }

    }

    return (
        <div style={menuStyle} className="layer-menu">
            <h4>{"LAYERS"}</h4>
            <div  className="expander-btn" onClick={handleExpanderClick}>Hide</div>

            {layers.map((layer, idx)=>{
                const layerStyle = layer.visible ? {backgroundColor: "rgb(70, 88, 100)"} : {backgroundColor: "#636363"};
                return <div key={idx} style={layerStyle} className="menu-item" onClick={menuItemClick} value={idx} name={idx}>{layer.name}
                    {layer.visible 
                        ? <img className="icon-tick"src={TickImg} alt='tick' /> 
                        : <img className="icon-tick"src={UntickedImg} alt='tick' />}</div>
            })}
            <Link to="/"><div key="1"  className="menu-item menu-item-back" value="1" name="back"><img src={BackArrowImg} alt="" />BACK</div></Link>
        </div>
    )
}

export default LayerMenu;