import React from "react";
import './Popup.css'
import { usePopup} from '../context/popup.context'

let Popup = (options) => {

    const { popupData, setPopupData } = usePopup()

    //this will hide the popup menu
    let handleClick = _ => {
        setPopupData(prevValue=>{
            return {...prevValue, visible: false}
        })
    }

    const pStyle = {
        display: popupData.visible===true ? "block": "none",
        // width: popupData.visible===true ? "max-content" : "0px"
    }


    return (
        <div className="popup-menu" id="popup" style={pStyle}>
            <div onClick={handleClick} className="close-btn">{'\u2715'}</div>
            <div className="popup-header"><span>{popupData.title.toUpperCase()}</span></div>
            {typeof popupData.data === "string" 
                    ? <img className="popup-img-overview" alt="curPopup.title.toUpperCase()" src={popupData.data} /> 
                    : <div>{popupData.data}</div>
            }
        </div>
    )
}

export default Popup;