

import ILTSBaseMap from '../media/maplayers/ilts_base_map.webp';
import CTCBaseMap from '../media/maplayers/ctc_core_base.webp';
import CTCPopup from '../media/popups/ctc_popup.webp';
import ARDSPopup from '../media/popups/ards_popup.webp'
import ARDSFuture from '../media/maplayers/ards_future_dev.webp'
import ARDSShort from '../media/maplayers/ards_short_term.webp'
import ARDSBaseMap from '../media/maplayers/ards_base.webp'
import AirBaseMap from '../media/maplayers/air_ranges_base.webp'
import MDLVCMap from '../media/maplayers/mdlvc_base.webp'
import MDLVCPreExImg from '../media/maplayers/mdlvc_pre-ex.webp'
import MDLVCViewsImg from '../media/maplayers/mdlvc_views.webp'
import CBRNDScenario from '../media/maplayers/cbrnd_scenario.webp'
import GTRAEx1 from '../media/maplayers/gtra_ex1.webp'
import GTRAEx2 from '../media/maplayers/gtra_ex2.webp'
import GTRABackhaul from '../media/popups/gtra_backhaul.webp'
import GTRACamera from '../media/popups/gtra_camera.webp'
import GTRIteWifi from '../media/popups/gtra_ltewifi.webp'
import GTRAStepUp from '../media/popups/gtra_stepup.webp'
// import CTCBaseMap from '../media/maplayers/ilts_base_map.png';
import CAALRNetImg from '../media/maplayers/ilts_caalr_net.png'
import CAALRPopup from '../media/popups/caalr_popup.webp'
import ILTSUOLFPopup from '../media/popups/uolf_popup.webp'
import ILTSMCSPopup from '../media/popups/ilts_mcs.webp'
import ILTSMTRPopup from '../media/popups/ilts_mtr_popup.webp'
import ASMTIBaseNet from '../media/maplayers/asmti_base_net.webp'
import ASMTIRange from '../media/maplayers/asmti_range.webp'
import ASMTIVisiting from '../media/maplayers/asmti_visiting.webp'
import ASMTIAustrlian from '../media/maplayers/asmti_australian.webp'
import ASMTIPopup from '../media/popups/asmti_popup.webp'
import ASMTISWBTAPopup from '../media/popups/asmti_swbta_popup.webp'
import ASMTISWBTATowerPopup from '../media/popups/swbta_towers_popup.webp'
import ASMTISWBTATowerVisPopup from '../media/popups/asmti_comms_tower_vis_popup.webp'
import CBRNDDetectPopup from '../media/popups/cbrnd_detector.webp'
import CBRNDGasPopup from '../media/popups/cbrnd_popup_3d.webp'
import CBRNDCatsPopup from '../media/popups/cats_cbrnd.webp'
import CBRNDEquipPopup from '../media/popups/cbrnd_equip_popup2.webp'
import ILTSEccCamp from '../media/maplayers/camp_ecc.png'
import ILTSMCS from '../media/maplayers/ilts_mcs.png'
import ILTSMtr from '../media/maplayers/ilts_mtr.png'
import ILTSMtrNet from '../media/maplayers/ilts_mtr_net.png'
import ILTSUOLF from '../media/maplayers/ilts_uolf.png'
import ILTSUOLFNet from '../media/maplayers/ilts_uolf_net.png'
import ILTSCaalr from '../media/maplayers/ilts_caalr.png'
import CTCExcon from '../media/maplayers/ctc_core_excon.webp'
import CTCAAR from '../media/maplayers/ctc_core_aar.webp'
import CTCCoaching from '../media/maplayers/ctc_core_coaching.webp'

import SAFTIBaseMap from '../media/maplayers/safti_base_map.webp'
import SAFTIBldLabels from '../media/maplayers/safti_bld_labels.webp'
import SAFTIUndergroundBld from '../media/maplayers/safti_under_bld.webp'
import SAFTIEquip from '../media/maplayers/safti_equip.webp'
import SAFTIUnderBldPopup from '../media/popups/safti_under_popup.webp'
import SAFTITrapdoorPopup from '../media/popups/safti_trapdoor_popup.webp'

import ManWearBase from '../media/maplayers/manwear_base.webp'
import ManWearFLashLayer from '../media/maplayers/manwear_flash_layer2.webp'

import VehicleKitsBase from '../media/maplayers/vehiclekit_base.webp'
import VehicleKitsVHI from '../media/maplayers/vehiclekit_vhi.webp'
import VehicleKitsRLDMS from '../media/maplayers/vehiclekit_rldms.webp'
import VehicleKitsSSGD from '../media/maplayers/vehiclekit_ssgd.webp'

export const MapData = () => {

    const maps = {
        ilts: {
            title: "Integrated Land Target System",
            baseMapImgs: [ILTSBaseMap, ILTSMCS],
            baseInfoBtns: [{
                title: "MCS",
                top: "80%",
                left: "25%",
                data: ILTSMCSPopup
            }],
            layers: [
                {
                    name: "MTR",
                    visible: true,
                    imgs: [
                        ILTSMtr
                    ],
                    infoBtns: [{
                        title: "MTR Range Info",
                        top: "80%",
                        left: "60%",
                        data: ILTSMTRPopup
                    }]
                },
                {
                    name: "MTR Network",
                    visible: false,
                    imgs: [
                        ILTSMtrNet
                    ],
                    infoBtns: []
                },
                {
                    name: "CAALR",
                    visible: false,
                    imgs: [ILTSCaalr],
                    infoBtns: [{
                        title: "CAALR",
                        top: "35%",
                        left: "40%",
                        data: CAALRPopup
                    }]
                },
                {
                    name: "CAALR Network",
                    visible: false,
                    imgs: [CAALRNetImg],
                    infoBtns: []
                },
                {
                    name: "UOLF",
                    visible: false,
                    imgs: [ILTSUOLF],
                    infoBtns: [{
                        title: "UOLF",
                        top: "59%",
                        left: "67%",
                        data: ILTSUOLFPopup
                    }]
                },
                {
                    name: "UOLF Network",
                    visible: false,
                    imgs: [ILTSUOLFNet],
                    infoBtns: []
                }
            ],

        },
        ctc: {
            title: "Army Training",
            baseMapImgs: [CTCBaseMap],
            baseInfoBtns: [{
                title: "Training Services",
                top: "37%",
                left: "60%",
                data: CTCPopup
            }],
            layers: [
                {
                    name: "Excon",
                    visible: false,
                    imgs: [
                        CTCExcon
                    ],
                    infoBtns: []
                },
                {
                    name: "AAR",
                    visible: false,
                    imgs: [CTCAAR],
                    infoBtns: []
                },
                {
                    name: "LPA",
                    visible: false,
                    imgs: [CTCCoaching],
                    infoBtns: []
                }
            ],

        },
        asmti: {
            title: "Australia - Singapore Military Training Initiative (ASMTI)",
            baseMapImgs: [ILTSBaseMap],
            baseInfoBtns: [{
                title: "Logical Network",
                top: "71%",
                left: "25%",
                data: ASMTIPopup
            },
            {
                title: "LVC Communication Tower",
                top: "58%",
                left: "31%",
                data: ASMTISWBTATowerPopup
            },
            {
                title: "LVC Communication Tower - 2",
                top: "44%",
                left: "87%",
                data: ASMTISWBTATowerVisPopup
            },
            {
                title: "SWBTA Infrastructure",
                top: "56%",
                left: "63%",
                data: ASMTISWBTAPopup
            }],
            layers: [
                {
                    name: "Range Management",
                    visible: false,
                    imgs: [
                        ASMTIRange
                    ],
                    infoBtns: []
                },
                {
                    name: "Visiting Forces",
                    visible: false,
                    imgs: [ASMTIVisiting],
                    infoBtns: []
                },
                {
                    name: "Australian Forces",
                    visible: false,
                    imgs: [ASMTIAustrlian],
                    infoBtns: []
                }
            ],
        },
        manWear: {
            title: "Soldier Kits",
            baseMapImgs: [ManWearBase],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Pan Harness",
                    visible: false,
                    imgs: [
                        ManWearFLashLayer
                    ],
                    class: "pulsate",
                    infoBtns: []
                }
            ],
        },
        vehiclekits: {
            title: "Vehicle Kits",
            baseMapImgs: [VehicleKitsBase],
            baseInfoBtns: [],
            layers: [
                {
                    name: "VHI",
                    visible: false,
                    imgs: [
                        VehicleKitsVHI
                    ],
                    class: "pulsate",
                    infoBtns: []
                },
                {
                    name: "SSGD",
                    visible: false,
                    imgs: [
                        VehicleKitsSSGD
                    ],
                    class: "pulsate",
                    infoBtns: []
                },
                {
                    name: "RLDMS",
                    visible: false,
                    imgs: [
                        VehicleKitsRLDMS
                    ],
                    class: "pulsate",
                    infoBtns: []
                }
            ],
        },
        air_ranges: {
            title: "Air Ranges and Debriefing Systems",
            baseMapImgs: [ARDSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Short Term",
                    visible: false,
                    imgs: [ARDSShort],
                    infoBtns: []
                },
                {
                    name: "Future Dev",
                    visible: false,
                    imgs: [ARDSFuture],
                    infoBtns: []
                }
            ],

        },
        mdlvc: {
            title: "Multi Domain LVC",
            baseMapImgs: [MDLVCMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Pre Exercise",
                    visible: false,
                    imgs: [MDLVCPreExImg],
                    infoBtns: []
                },
                {
                    name: "LVC Views",
                    visible: false,
                    imgs: [MDLVCViewsImg],
                    infoBtns: []
                }
            ],

        },
        gtra: {
            title: "Open Architecture",
            baseMapImgs: [ILTSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Exercise 1",
                    visible: true,
                    imgs: [GTRAEx1],
                    infoBtns: [{
                        title: "Camera Node",
                        top: "30%",
                        left: "25%",
                        data: GTRACamera
                    }]
                },
                {
                    name: "Exercise 2",
                    visible: false,
                    imgs: [GTRAEx2],
                    infoBtns: [{
                        title: "Backhaul Node",
                        top: "68%",
                        left: "46%",
                        data: GTRABackhaul
                    },
                    {
                        title: "LTE / WIFI",
                        top: "38%",
                        left: "68%",
                        data: GTRIteWifi
                    },
                    {
                        title: "GRS",
                        top: "68%",
                        left: "67%",
                        data: GTRAStepUp
                    }]
                }
            ],

        },
        cbrnd: {
            title: "Chemical, Biological, Radiological and Nuclear (CBRN)",
            baseMapImgs: [ILTSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Scenario",
                    visible: false,
                    imgs: [CBRNDScenario],
                    infoBtns: [{
                        title: "CBRN Simulated Chemical Hazard",
                        top: "45%",
                        left: "55%",
                        data: CBRNDGasPopup
                    }, {
                        title: "CBRN Simulated Devices",
                        top: "51%",
                        left: "48%",
                        data: CBRNDEquipPopup
                    }]
                },
                {
                    name: "EXCON",
                    visible: false,
                    imgs: [ILTSEccCamp],
                    infoBtns: [{
                        title: "CATS 2D Interface - Chemical Simulation ",
                        top: "77%",
                        left: "51%",
                        data: CBRNDCatsPopup
                    }]
                }

            ],

        },
        safti: {
            title: "SAFTI City",
            baseMapImgs: [SAFTIBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Buildings",
                    visible: false,
                    imgs: [SAFTIBldLabels],
                    infoBtns: []
                },
                {
                    name: "Underground Building",
                    visible: false,
                    imgs: [SAFTIUndergroundBld],
                    infoBtns: [{
                        title: "Simulated Underground Building",
                        top: "40%",
                        left: "56%",
                        data: SAFTIUnderBldPopup
                    }]
                },
                {
                    name: "Equipment",
                    visible: false,
                    imgs: [SAFTIEquip],
                    infoBtns: [{
                        title: "Trapdoor",
                        top: "40%",
                        left: "82%",
                        data: SAFTITrapdoorPopup
                    }]
                }
            ],
        }
    }

    const getById = (id) => {
        if (!maps[id]) throw new Error(`No map with that ID exists: ${id}`);
        return maps[id];
    }
    return {
        maps,
        getById
    };

}
